<template>
	<div>
		<CContainer class="d-flex content-center" style="margin-bottom: 2%;">
			<loading-screen v-if="isLoading"></loading-screen>
			<CCard>
				<CCardBody>
					<div v-if="this.showInformation">
						<!-- titulo -->
						<CRow>
							<CCol sm="12" class="margin-top-main">
								<h1 class="text-title-main text-bolder">
									<span
										class="d-block"
										:style="this.fontFamily.outFit"
										>¿Instagram en tu perfil
									</span>
									<span
										class="d-block text-yellow text-center"
										:style="this.fontFamily.outFit"
										> de Infoguia?
									</span>
								</h1>
							</CCol>
						</CRow>
						<!-- informacion -->
						<CRow class="padding-content">
							<CCol sm="12" class="mt-3 mt-lg-5">
								<p class="text-information justify-text">
									<span
										:style="this.fontFamily.nunito">
										Ahora, activar el widget de Instagram,
										para mostrar tu perfil en tiempo real,
										es más sencillo de lo que crees.
									</span>
								</p>
							</CCol>
						</CRow>
						<!-- Elementos a considerar -->
						<CRow class="padding-content py-2 py-md-5">
							<CCol sm="12" class="pb-5">
								<CRow>
									<CCol>
										<h2 class="text-subtitle-main justify-text text-bolder">
											<span
												:style="this.fontFamily.outFit"
												>Elementos a considerar
											</span>
										</h2>
									</CCol>
								</CRow>
								<!-- Contenedor con bordes -->
								<CRow>
									<CCol sm="12" class="box-elements-consideration mt-3">
										<CRow class="d-flex align-items-center">
											<CCol class="col-2 col-md-1">
												<p class="text-number text-bolder">
													<span>1</span>
												</p>
											</CCol>
											<CCol class="col-10 col-md-11">
												<p class="d-block d-lg-none text-information-box justify-text mt-4">
													<span>
														Antes de iniciar, asegúrate de no tener ningún usuario personal
														de Instagram abierto, en el dispositivo en el que harás la activación.
														De esta manera, evitarás vincular algún perfil personal con tu registro
														de empresa en Infoguia
													</span>
												</p>
												<p class="d-none d-lg-block h3 justify-text">
													<span>
														Antes de iniciar, asegúrate de no tener ningún usuario personal
														de Instagram abierto, en el dispositivo en el que harás la activación.
														De esta manera, evitarás vincular algún perfil personal con tu registro
														de empresa en Infoguia
													</span>
												</p>
											</CCol>
										</CRow>
										<hr class="solid-top" />
										<CRow class="d-flex align-items-center">
											<CCol class="col-2 col-md-1">
												<p class="text-number text-bolder">
													<span>2</span>
												</p>
											</CCol>
											<CCol class="col-10 col-md-11">
												<p class="d-block d-lg-none text-information-box justify-text mt-4">
													<span>
														Si la cuenta de Instagram de tu empresa es privada debes realizar
														este proceso cada 60 días. Te recomendamos cambiar la configuración
														de tu cuenta a PÚBLICA para evitar que se le solicite nuevamente
														la autorización.
													</span>
												</p>
												<p class="d-none d-lg-block h3 justify-text">
													<span>
														Si la cuenta de Instagram de tu empresa es privada debes realizar
														este proceso cada 60 días. Te recomendamos cambiar la configuración
														de tu cuenta a PÚBLICA para evitar que se le solicite nuevamente
														la autorización.
													</span>
												</p>
											</CCol>
										</CRow>
									</CCol>
								</CRow>
							</CCol>
						</CRow>
						<!-- pasos -->
						<!-- paso 1 -->
						<CRow class="background-gray-light padding-content">
							<CCol sm="12">
								<CRow class="d-md-flex align-items-md-center">
									<CCol xs="12" lg="7" class="py-3 py-lg-5">
										<CRow>
											<CCol sm="12">
												<p class="d-block d-lg-none text-colors-begin text-bolder text-center mt-2"
													:style="this.fontFamily.outFit">
													<span class="text-yellow">¿</span>
													<span class="text-red">Co</span>
													<span class="text-blue-dark">men</span>
													<span class="text-purple">za</span>
													<span class="text-green">mos</span>
													<span class="text-blue-light">?</span>
												</p>
												<p class="d-none d-lg-block text-colors-begin text-bolder mt-2"
													:style="this.fontFamily.outFit">
													<span class="text-yellow">¿</span>
													<span class="text-red">Co</span>
													<span class="text-blue-dark">men</span>
													<span class="text-purple">za</span>
													<span class="text-green">mos</span>
													<span class="text-blue-light">?</span>
												</p>
												<div class="d-block d-lg-none">
													<CImg
														class="img-fluid"
														:src="this.images.robotBeginMobile"
														alt="comencemos mobile"
													/>
												</div>
												<p class="text-title-item left-text mt-3 mt-lg-5" :style="this.fontFamily.outFit">
													<span class="text-bolder">Paso 1: </span>
													<span class="text-semibolder">Inicia la activación</span>
												</p>
												<p class="text-paragraph justify-text mt-3 mt-lg-5">
													<span style="font-family: Montserrat-Regular;">Haz click en el siguiente botón para iniciar el proceso:</span>
												</p>
												<!-- botón -->
												<div class="d-md-flex justify-content-md-start mt-3 mb-3 mt-lg-5 mb-lg-0">
													<CButton
														@click="clickAuth"
														color="warning"
														shape="pill"
														class="btn-activate-widget text-btn-widget-activate"
														style="font-family: Montserrat-Bold;"
														>¡Quiero activar mi widget!
													</CButton>
												</div>
											</CCol>
										</CRow>
									</CCol>
									<CCol xs="12" lg="5" class="d-none d-lg-block">
										<CImg
											class="img-fluid"
											:src="this.images.robotBegin"
											alt="comencemos"
										/>
									</CCol>
								</CRow>
							</CCol>
						</CRow>


						<!-- paso 2 -->
						<CRow id="step2" class="py-3 py-lg-5 padding-content">
							<CCol sm="12" class="py-5">
								<CRow>
									<CCol sm="12">
										<p class="text-title-item left-text" :style="this.fontFamily.outFit">
											<span class="text-bolder">Paso 2: </span>
											<span class="text-semibolder">Inicia sesi&oacute;n</span>
										</p>
									</CCol>
								</CRow>
								<br />
								<CRow class="content-center">
									<CCol sm="12">
										<p
											class="text-paragraph justify-text"
											style="font-family: 'Montserrat-Regular';"
										>
											Ingresa a la cuenta de Instagram
											de tu empresa. Si ya tienes la
											sesión iniciada, continúa al
											<a
												href="#step3"
												class="text-paragraph"
												style="font-family: 'Montserrat-Bold'; text-decoration: underline;"
												>Paso 3</a
											>.
										</p>
									</CCol>
								</CRow>
								<CRow>
									<CCol sm="12" class="d-flex justify-content-center">
										<img
											class="img-fluid d-block d-lg-none"
											:src="this.images.instagramLoginMobile"
											alt="instagram-login"
										/>
										<img
											class="img-fluid d-none d-lg-block"
											style="margin-top: 5%;"
											:src="this.images.instagramLogin"
											alt="instagram-login"
										/>
									</CCol>
								</CRow>
							</CCol>
						</CRow>
						<!-- paso 3 -->
						<CRow id="step3" class="py-3 py-lg-5 background-gray-light padding-content">
							<CCol sm="12" class="py-3 py-lg-5">
								<CRow>
									<CCol sm="12">
										<p class="text-title-item left-text" :style="this.fontFamily.outFit">
											<span class="text-bolder">Paso 3: </span>
											<span class="text-semibolder">Autoriza</span>
										</p>
									</CCol>
								</CRow>
								<br />
								<CRow>
									<CCol sm="12">
										<p
											class="text-paragraph justify-text"
											style="font-family: 'Montserrat-Regular';"
										>
											Instagram te mostrará un anuncio en pantalla,
											solicitando los permisos para acceder y compartir
											las publicaciones de tu cuenta. Esto podría atraer
											a más clientes potenciales a tu contenido.
											Para continuar, debes aceptar el acceso.
										</p>
									</CCol>
								</CRow>
								<CRow>
									<CCol sm="12">
										<div class="d-flex justify-content-center">
											<img
												class="img-fluid d-block d-lg-none"
												:src="
													this.images.instagramPermissionsMobile
												"
												alt="mano-instagram"
											/>
											<img
												class="img-fluid d-none d-lg-block"
												:src="
													this.images.instagramPermissions
												"
												alt="mano-instagram"
											/>
										</div>
										<p
											class="text-paragraph justify-text mt-5"
											style="font-family: 'Montserrat-Regular';"
										>
											Una vez hayas permitido el acceso, serás
											redireccionado a otra pantalla en la cual
											deberás esperar unos segundos a que el
											sistema active el widget en tu perfil
											de Infoguia.
										</p>
									</CCol>
								</CRow>
							</CCol>
						</CRow>
						<!-- Sección listo -->
						<CRow>
							<CCol xs="12" lg="6" class="d-none d-lg-block mt-3 mt-lg-5 padding-content">
								<CImg
									class="img-fluid d-none d-lg-block"
									:src="this.images.robotReady"
									alt="listo"
								/>
							</CCol>
							<CCol xs="12" lg="6" class="d-flex align-items-center py-3 py-lg-0">
								<div class="col-12">
									<div class="col-12">
										<p class="text-colors-ready d-flex justify-content-center justify-content-lg-start text-bolder"
											:style="this.fontFamily.outFit">
											<span class="text-yellow">¡Y&nbsp;</span>
											<span class="text-red">l</span>
											<span class="text-blue-dark">i</span>
											<span class="text-purple">s</span>
											<span class="text-green">t</span>
											<span class="text-blue-ligth">o!</span>
										</p>
									</div>
									<CCol xs="12" class="d-flex d-lg-block justify-content-center">
										<!-- mobile -->
										<p class="d-block d-lg-none text-information text-bolder"
										style="font-family: 'Montserrat-Regular';">
											<span class="d-block">
												¿Preparado para aumentar el
											</span>
											<span class="d-block">
												tráfico hacia tus redes sociales?
											</span>
										</p>
										<!-- desktop -->
										<p class="d-none d-lg-block text-information justify-text text-bolder"
										style="font-family: 'Montserrat-Regular';">
											<span class="d-block">
												¿Preparado para aumentar el
											</span>
											<span class="d-block">
												tráfico hacia tus redes sociales?
											</span>
										</p>
									</CCol>
									<CCol xs="12" class="d-block d-lg-none mt-3 mt-lg-5 padding-content">
										<CImg
											class="img-fluid"
											:src="this.images.robotReadyMobile"
											alt="listo"
										/>
									</CCol>
								</div>
							</CCol>
						</CRow>
						<br />
					</div>
					<div v-else-if="this.error">
						<fail-auth
							:error-message="this.errorMessage"
						></fail-auth>
					</div>
				</CCardBody>
			</CCard>
		</CContainer>
	</div>
</template>
<script>
	import functionsForCompany from '../../functions/functionsForCompany';
	import { FailAuth } from '../../components/indexComponents';
	export default {
		name: 'Welcome',
		title: 'Bienvenido Infoamigo | Infoguia',
		beforeCreate: function() {
			sessionStorage.clear();
		},
		created() {
			this.securityHash = this.$route.query.sh;
			sessionStorage.setItem('securityHash', this.$route.query.sh);
			this.isLoading = true;
			this.$Progress.start();
			functionsForCompany
				.getCompanyAuthData(this.securityHash)
				.then((response) => {
					this.dataCustomer = response;
					this.tokenApi = response.token;
					localStorage.setItem('token', response.token);
					this.showInformation = true;
					this.$Progress.finish();
				})
				.catch((error) => {
					let errorResponse = error.response.data;
					console.log(errorResponse);
					this.showInformation = false;
					this.error = true;
					this.errorMessage = errorResponse.error;
					this.$Progress.fail();
				})
				.finally(() => {
					this.isLoading = false;
					//this.$Progress.finish();
				});
		},
		data() {
			return {
				securityHash: '',
				showInformation: false,
				error: false,
				errorMessage: '',
				isLoading: false,
				dataCustomer: {},
				tokenApi: '',
				images: {
					instagramLogin: process.env.VUE_APP_LOGIN_INSTAGRAM,
					instagramPermissions:
						process.env.VUE_APP_PERMISSIONS_INSTAGRAM,
					stepsFooter: process.env.VUE_APP_STEPS_FOOTER,
					handIg: process.env.VUE_APP_WELCOME_HAND_IG,
					robotBegin: process.env.VUE_APP_WELCOME_ROBOT_BEGIN,
					handBegin: process.env.VUE_APP_WELCOME_HAND_BEGIN,
					error: process.env.VUE_APP_ERROR_ACTIVATION,
					arrow: process.env.VUE_APP_ARROW02,
					robotReady: process.env.VUE_APP_ROBOT_READY,
					instagramLoginMobile: process.env.VUE_APP_LOGIN_INSTAGRAM_MOBILE,
					instagramPermissionsMobile:
						process.env.VUE_APP_PERMISSIONS_INSTAGRAM_MOBILE,
					robotBeginMobile: process.env.VUE_APP_WELCOME_ROBOT_BEGIN_MOBILE,
					robotReadyMobile: process.env.VUE_APP_ROBOT_READY_MOBILE,
				},
				fontFamily: {
					outFit : "font-family: Outfit, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif",
					nunito : "font-family: Nunito Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif"
				}
			};
		},
		components: {
			FailAuth,
		},
		methods: {
			clickAuth() {
				window.location.replace(
					process.env.VUE_APP_API_INSTAGRAM +
						'?client_id='+process.env.VUE_APP_CLIENT_ID_INSTAGRAM+'&redirect_uri=' +
						process.env.VUE_APP_URL +
						'/customers/auth/&response_type=code&scope=instagram_business_basic&state=' +
						this.securityHash
				);
			},
		},
	};
</script>
